import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import PlayerDisplay from './PlayerDisplay';
import { getPlayer, Player } from './api';

const toId = (text: string) => text.toLowerCase().replace(/[^a-z0-9]+/g, '');

const App = () => {
  const [showdownUsername, setShowdownUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    const showdownId = toId(showdownUsername);
    if (showdownId !== player?.showdownId) {
      setLoading(true);
      getPlayer(showdownId)
        .then((player) => {
          setPlayer(player);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [player, showdownUsername]);

  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '10px' }}>
    <TextField label='Showdown Username' variant='standard' size='small' onChange={(event) => setShowdownUsername(event.target.value)} />
    <PlayerDisplay player={player} loading={loading} />
  </div>
};

export default App;
