import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Player } from './api';

const Avatar = ({ avatar }: { avatar?: string }) => {
  const [src, setSrc] = useState<string>();
  const [exists, setExists] = useState(true);

  const actualSrc = src || 'https://play.pokemonshowdown.com/sprites/trainers/unknown.png';

  if (avatar && exists && !src) {
    setSrc(`https://play.pokemonshowdown.com/sprites/trainers/${avatar}.png`);
  }

  return <img src={actualSrc} onError={() => { setSrc(undefined); setExists(false); }} alt='player avatar'/>;
};

const PlayerDisplay = ({ loading, player }: { loading: boolean, player?: Player }) => {
  let child: React.ReactNode = <Typography variant='h5' sx={{ display: 'block' }}>No player found</Typography>;

  if (loading) {
    child = <CircularProgress />;
  } else if (player) {
    const totalTrackedGames = player.totalWins + player.totalTies + player.totalLosses;
    const totalChampGames = player.totalLegacyChampBattles + totalTrackedGames;
    const winrate = (player.totalWins + (player.totalTies * 0.5)) / totalTrackedGames;
    const roundedWinrate = Math.floor(winrate * 10000) / 100;
    const tooltipText = `${player.totalWins} / ${player.totalLosses} / ${player.totalTies} (W / L / T)`;

    child = <><Link variant='h5' href={`https://pokemonshowdown.com/users/${player.showdownId}`} sx={{ display: 'block' }}>{player.showdownUsername || player.showdownId}</Link>
        <Avatar avatar={player.avatar} />
        <Typography variant='body2' sx={{ display: 'block' }}>Seen in {player.totalBattles} games</Typography>
        <Typography variant='body2' sx={{ display: 'block' }}>Champed {totalChampGames} games</Typography>
        { !Number.isNaN(roundedWinrate) && 
          <Typography variant='body2' sx={{ display: 'block' }}>Has a <Tooltip title={tooltipText}><span>{roundedWinrate + '%'}</span></Tooltip> win rate</Typography> }
    </>;
  }

  return <Paper elevation={3} sx={{ width: '240px', padding: '10px', textAlign: 'center' }}>
    {child}
  </Paper>;
};

export default PlayerDisplay;
