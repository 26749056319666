import Axios from 'axios';

export interface Player {
  showdownId: string;
  showdownUsername?: string;
  totalLegacyChampBattles: number;
  totalBattles: number;
  totalWins: 0;
  totalTies: 0;
  totalLosses: 0;
  lastUpdated: string;
  avatar?: string;
}

export const getPlayer = async (showdownId: string): Promise<Player | undefined> => {
  try {
    const { data } = await Axios.get(`https://api.derp.show/player/${showdownId}`, { responseType: 'json' });

    return (data as { player: Player }).player;
  } catch (error) {}
};
